<template>
  <div class="apply">
    <div class="card">
      <div class="container">
        <img :src="info.images" />
        <div class="info">
          <h1>
            {{ info.bookname }}
            <br />
            <span>有效期{{ info.validity == 0 ? "永久" : info.validity+'天' }}</span>
          </h1>
          <p class="gold-num">￥{{ info.price }}</p>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="box">
        <div class="title">上课地址</div>
        <div v-if="slots[0].values.length > 0">
          <mt-picker :slots="slots" value-key="alias" @change="onValuesChange"></mt-picker>
        </div>
        <div v-else class="noAddress">
          暂无上课地址
        </div>
      </div>

      <div class="box text" v-show="slots[0].values.length > 0">
        <h2>上课说明：</h2>
        <p>
          因课程现场席位有限，为了有效避免学员报名课程后实际上并没有来到现场学习而造成的学习资格浪费，
          导致想参加现场学习的人无法参加学习。也为了让聚亿美工作人员提前做好会前准备工作，给学员提供更好的服务和学习体验
        </p>
        <p>
          从2019年1月22日起，所有学员若要参加线下课程，课程现场学习，
          需要交纳
          <span class="gold-num">{{ positionPrice }}</span>元的座位保证金，在到达当期课程现场签到成功后退回
          <span class="gold-num">{{ positionPrice }}</span>元。
        </p>
        <p>
          我们支持签到日10天以前改签不扣款，累计免费改签2次。（例如：线下课程若3月18日开课，3月17日签到，
          免费改签时间截止至3月6日24:00，3月7日00:00起不可操作改签，
          <span class="gold-num">{{ positionPrice }}</span>元保证金费用不可退，不可下次使用）。
        </p>
        <p class="bottom">
          聚亿美教育咨询有限公司
          <br />2019年1月22日
        </p>

        <mt-checklist v-model="isSelect" :options="options"></mt-checklist>

        <button
          @click="submit"
          :class="isSelect ? 'btn-blue' : 'btn-black'"
          :disabled="!isSelect"
        >提交</button>
      </div>
    </div>
  </div>
</template>

<script>
import { detailOffline } from "@api/public";
export default {
  name: "apply",
  data() {
    return {
      id: "",
      sid: "",
      positionPrice: "",
      info: {},
      slots: [
        {
          values: [],
          textAlign: "center"
        }
      ],
      isSelect: false,
      options: [
        {
          label: "我已同意并阅读",
          isSelect: false
        }
      ]
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetailData();
  },
  methods: {
    // 获取列表数据
    getDetailData() {
      let that = this;

      detailOffline(that.id).then(res => {
        that.$set(that, "info", res.data.info);
        that.$set(that, "positionPrice", res.data.positionPrice);
        // 赋值上课地址
        if(res.data.offline) {
          that.$set(that.slots[0], "values", res.data.offline);
        }
      });
    },

    onValuesChange(picker, values) {
      if(values[0]) {
        this.sid = values[0].id
      }
    },

    submit() {
      let that = this;

      if(that.sid) {
        that.$router.push({
          path: "/lesson/applyDetail",
          query: {id: that.id, sid: that.sid}
        });
      } else {
        that.$dialog.error('请选择上课地点！')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.apply {
  .card {
    background: white;
    .container {
      padding: 0.3rem;
      display: flex;
      justify-content: space-between;
      height: 2.6rem;
      img {
        width: 50%;
        height: 100%;
        background: black;
        border-radius: 0.2rem 0 0 0.2rem;
      }
      .info {
        width: 50%;
        padding: 0.1rem 0.2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        box-shadow: 0 0.02rem 0.06rem #dddddd;
        border-radius: 0 0.2rem 0.2rem 0;
        h1 {
          font-size: 0.28rem;
          font-weight: 500;
          span {
            font-weight: 500;            
            font-size: .24rem;
          }
        }
      }
    }
  }

  .main {
    padding: 0.3rem;
    .box {
      background: white;
      border-radius: 0 0 0.2rem 0.2rem;
      margin-bottom: 0.3rem;
      .noAddress {
        height: 2rem;
        line-height: 2rem;
        padding: 0 .3rem;
      }
      .title {
        height: 0.8rem;
        line-height: 0.8rem;
        padding-left: 0.2rem;
        color: white;
        background-image: linear-gradient(to right, #141518, #2f3237);
        border-radius: 0.2rem 0.2rem 0 0;
      }
      button {
        margin: 0.3rem;
        font-size: 0.26rem;
      }
    }
    .text {
      border-radius: 0.2rem;
      padding: 0.2rem 0.3rem;
      h2 {
        font-size: 0.28rem;
        font-weight: 500;
        margin: 0.2rem 0;
      }
      p {
        font-size: 0.26rem;
        line-height: 0.36rem;
        margin-bottom: 0.1rem;
        text-indent: 2em;
      }
      .bottom {
        margin-top: .8rem;
        text-indent: 0;
      }
      button {
        font-size: 0.32rem;
        color: #fff;
        width: 100%;
        height: 0.9rem;
        border-radius: 0.1rem;
        text-align: center;
        line-height: 0.9rem;
        margin: 0.3rem auto;
      }
    }
  }
}
</style>
